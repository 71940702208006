import React from 'react'
import LightboxLayout from '../../components/lightboxLayout'

const Page = () => {
  return (
    <LightboxLayout>
      <h2>ROOT CANAL TREATMENT</h2>
      <h3>What is it?</h3>
      <p>Teeth are held in the jaws by their roots. Front teeth normally have one root, but teeth further back have more. At the core of each tooth is a soft mass of tissue called the pulp. In a healthy tooth, the pulp contains living fibres, cells, nerves and a blood supply, extending into the root(s) through the root canal(s). Decay or injury can destroy the living pulp.  Because dead pulp lacks a blood supply, it is more prone to infection, leading to an abscess and toothache. Root canal treatment (also known as root filling or endodontics) means removing damaged or dead pulp and filling the space left. The remaining tooth can then be repaired.</p>
      <h3>What will my dentist do?</h3>
      <p>An x-ray can show the number and shape of the root canals, and also signs of infection  in the surrounding bone. Teeth can have a number of roots and some roots are easier to fill than others, because  of their shape.</p>
      <p>To keep root canals dry during treatment  the dentist may stretch a sheet of thin rubber around the tooth, on a frame outside  the mouth; this is called "rubber dam". Having this fitted makes the treatment more comfortable.</p>
      <p>You will be given a local anaesthetic, and then an opening is made through the top of the tooth, down into the pulp.</p>
      <p>The dentist then uses narrow files to remove the dead pulp from the core of the tooth and from the root canal(s).</p>
      <p>At this point,  the dentist  may put in a temporary filling. Occasionally it may be necessary to give you antibiotics, for example, if any infection has spread beyond the tooth and you have  a raised  temperature and feel unwell. If so, you will have to return at a later date, once symptoms have settled, so the dentist  can complete the treatment.</p>
      <p>In the next stage, the dentist fills the root canal(s). A filling is then placed in the remaining cavity in the top of the tooth. Or, if necessary, a crown can be placed on top of the tooth, supported by a post placed inside of the filled root canal.</p>
      <p>Root filled teeth can become darker than other teeth, but bleaching  can be used to make them look lighter.</p>
      <h3>What are the benefits?</h3>
      <p>Pulp damage can cause  toothache but the pain will usually end very quickly  when the root canal is cleaned out.</p>
      <p>Without a root filling, a tooth with a dead pulp would probably have to be taken out in the end. There is also a possibility of infection spreading beyond the tooth itself.</p>
      <p>Root-fillings are usually successful and can last many years, but re-treatment is also possible if infection recurs. Occasionally, if inflammation persists at the tip of the root, surgery can be carried out to remove part of the root, clean the area and put in a filling. This is known as an "apicectomy".</p>
    </LightboxLayout>
  )
}

export default Page